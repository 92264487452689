import React, { useEffect } from "react";
import { WebNavbar, Footer, Sidebar, ThemeSettings, Navbar } from "./components";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import * as Pages from "./pages/index";
import { useStateContext } from "./contexts/ContextProvider";
import ProtectedContent from "./ProtectedContent";

const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    setColor,
    currentMode,
    setMode,
    isLoggedIn,
  } = useStateContext();

  useEffect(() => {
    if (!currentColor) {
      setColor('#03C9D7');
    }
    if (!currentMode) {
      setMode('Light');
    }
  }, [currentColor, currentMode, setColor, setMode]);

  return (
    <div className={`${currentMode === "Dark" ? "dark" : ""}`}>
      <BrowserRouter>
        {isLoggedIn ? (
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                  style={{ background: currentColor, borderRadius: "50%" }}
                  onClick={() => setThemeSettings(true)}
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                  style={{ background: currentColor, borderRadius: "50%" }}
                  onClick={() => setThemeSettings(true)}
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${activeMenu ? "md:ml-72" : "flex-2"
                }`}
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}

                <ProtectedContent />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex relative dark:bg-main-dark-bg">
            <div
              className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full flex-2`}
            >
              <div>
                {themeSettings && <ThemeSettings />}
                <WebNavbar />
                <Routes>
                  <Route path="/" element={<Pages.Home />} />
                  <Route path="/login" element={<Pages.SignIn />} />
                  <Route path="/signup" element={<Pages.SignUp />} />

                  {/* People */}
                  <Route path='/team/richard-white' element={<Pages.RichardWhite />} />

                  {/* Services */}
                  <Route path='/services/accounting-and-advisory-services' element={<Pages.AccountingAndAdvisoryServices />} />
                  <Route path='/services/personal-financial-planning' element={<Pages.PersonalFinancialPlanning />} />
                  <Route path='/services/tax-planning-and-preparation' element={<Pages.TaxPlanningAndPreparation />} />
                  <Route path='/services/assurance-services' element={<Pages.AssuranceServices />} />
                  <Route path='/services/nonprofit-organizational-financial-services' element={<Pages.NonprofitOrganizationalFinancialServices />} />

                  {/* Tax Center */}
                  <Route path="/tax-center/track-your-refund" element={<Pages.TrackYourRefund />} />
                  <Route path="/tax-center/tax-due-dates" element={<Pages.TaxDueDates />} />
                  <Route path="/tax-center/irs-tax-forms-and-publications" element={<Pages.IRSTaxFormsAndPublications />} />
                  <Route path="/tax-center/record-retention-guide" element={<Pages.RecordRetentionGuide />} />
                  <Route path="/tax-center/state-tax-forms" element={<Pages.StateTaxForms />} />

                  {/* Portal */}
                  <Route path="/portal/" element={<Pages.FormLanding />} />
                  <Route path="/portal/:id" element={<Pages.FormLanding />} />

                </Routes>
                <Footer />
              </div>
            </div>
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

{
  /* <Routes>
  <Route path="/" element={<Pages.Home />} />
</Routes>; */
}

export default App;
