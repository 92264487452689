import React from 'react';

const TaxDueDates = () => {
  const taxDates = [
    {
      month: 'March 2025',
      events: [
        { date: 'March 10', details: 'Individuals – Report February tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'March 17',
          details: `Calendar-year S corporations – File a 2024 income tax return (Form 1120-S) and provide each shareholder with a copy of Schedule K-1 (Form 1120S) or a substitute Schedule K-1, or file for an automatic six-month extension (Form 7004). Pay any tax due.`,
        },
        {
          date: 'March 17',
          details: `Calendar-year partnerships – File a 2024 income tax return (Form 1065 or Form 1065-B) and provide each partner with a copy of Schedule K-1 (Form 1065) or a substitute Schedule K-1, or request an automatic six-month extension (Form 7004).`,
        },
        {
          date: 'March 17',
          details: 'Employers – Deposit Social Security, Medicare, and withheld income taxes for February if the monthly deposit rule applies.',
        },
      ],
    },
    {
      month: 'April 2025',
      events: [
        { date: 'April 1', details: 'Employers – Electronically file 2024 Form 1097, Form 1098, Form 1099 (other than those with an earlier deadline), and Form W-2G.' },
        { date: 'April 10', details: 'Individuals – Report March tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'April 15',
          details: 'Individuals – File a 2024 income tax return (Form 1040 or Form 1040-SR) or file for an automatic six-month extension (Form 4868). Pay any tax due.',
        },
        {
          date: 'April 15',
          details: 'Estimated Taxes – Pay the first installment of 2025 estimated taxes (Form 1040-ES) if not paying income tax through withholding or not paying sufficient income tax through withholding.',
        },
        {
          date: 'April 15',
          details: 'IRA Contributions – Make 2024 contributions to a traditional IRA or Roth IRA (even if a 2024 income tax return extension is filed).',
        },
        {
          date: 'April 15',
          details: 'Retirement Plans – Make 2024 contributions to a SEP or certain other retirement plans (unless a 2024 income tax return extension is filed).',
        },
        {
          date: 'April 15',
          details: 'Gift Tax Return – File a 2024 gift tax return (Form 709) or file for an automatic six-month extension (Form 8892). Pay any gift tax due. File for an automatic six-month extension (Form 4868) to extend both Form 1040 and Form 709 if no gift tax is due.',
        },
        {
          date: 'April 15',
          details: 'Household Employers – File Schedule H if wages paid equal $2,600 or more in 2024 and Form 1040 isn’t required to be filed. For those filing Form 1040, Schedule H is submitted with the return and is extended if the return is extended.',
        },
      ],
    },
    {
      month: 'May 2025',
      events: [
        {
          date: 'May 12',
          details: 'Individuals – Report April tip income of $20 or more to employers (Form 4070).',
        },
        {
          date: 'May 12',
          details: 'Educational Institutions – File Form 941 for the first quarter of 2025. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until May 12 to file the return.',
        },
        {
          date: 'May 15',
          details: 'Estate and Trust Income Tax Returns – File Form 1041 and pay any tax, interest, and penalties due. Provide each beneficiary with a copy of Schedule K-1 (Form 1041) or a substitute Schedule K-1.',
        },
        {
          date: 'May 15',
          details: 'Household Employers – If you paid cash wages of $2,100 or more in 2024 to a household employee, file Schedule H (Form 1040) with your income tax return and report any household employment taxes. Report any federal unemployment (FUTA) tax on Schedule H (Form 1040) if you paid total cash wages of $1,000 or more in any calendar quarter of 2024 or 2025 to household employees.',
        },
        {
          date: 'May 15',
          details: 'Employers – Nonpayroll taxes – If the monthly deposit rule applies, deposit the tax for payments in April.',
        },
      ],
    },
    {
      month: 'June 2025',
      events: [
        { date: 'June 10', details: 'Individuals – Report May tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'June 16',
          details: 'Individuals – Pay the second installment of 2025 estimated taxes (Form 1040-ES) if not paying income tax through withholding or not paying sufficient income tax through withholding.',
        },
        {
          date: 'June 16',
          details: 'Calendar-year Corporations – Pay the second installment of 2025 estimated income taxes, completing Form 1120-W for the corporation’s records.',
        },
        {
          date: 'June 16',
          details: 'Employers – Deposit Social Security, Medicare, and withheld income taxes for May if the monthly deposit rule applies.',
        },
        {
          date: 'June 16',
          details: 'Employers – Deposit nonpayroll withheld income tax for May if the monthly deposit rule applies.',
        },
      ],
    },
    {
      month: 'July 2025',
      events: [
        { date: 'July 10', details: 'Individuals – Report June tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'July 15',
          details: 'Employers – Deposit Social Security, Medicare, and withheld income taxes for June if the monthly deposit rule applies.',
        },
        {
          date: 'July 15',
          details: 'Employers – Deposit nonpayroll withheld income tax for June if the monthly deposit rule applies.',
        },
        {
          date: 'July 31',
          details: 'Employers – Report Social Security, Medicare taxes, and income tax withholding for the second quarter of 2025 (Form 941) and pay any tax due if all of the associated taxes due weren’t deposited on time and in full.',
        },
      ],
    },
    {
      month: 'August 2025',
      events: [
        { date: 'August 12', details: 'Individuals – Report July tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'August 12',
          details: 'Educational Institutions – Deposit federal unemployment (FUTA) tax owed through June if more than $500.',
        },
        {
          date: 'August 15',
          details: 'Employers – Deposit Social Security, Medicare, and withheld income taxes for July if the monthly deposit rule applies.',
        },
        {
          date: 'August 15',
          details: 'Employers – Deposit nonpayroll withheld income tax for July if the monthly deposit rule applies.',
        },
      ],
    },
    {
      month: 'September 2025',
      events: [
        { date: 'September 10', details: 'Individuals – Report August tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'September 15',
          details: 'Individuals – Pay the third installment of 2025 estimated taxes (Form 1040-ES) if not paying income tax through withholding or not paying sufficient income tax through withholding.',
        },
        {
          date: 'September 15',
          details: 'Calendar-year Corporations – Pay the third installment of 2025 estimated income taxes, completing Form 1120-W for the corporation’s records.',
        },
        {
          date: 'September 15',
          details: 'Calendar-year S Corporations – File a 2024 income tax return (Form 1120-S) and provide each shareholder with a copy of Schedule K-1 (Form 1120-S) if an automatic six-month extension was filed. Pay any tax, interest, and penalties due.',
        },
        {
          date: 'September 15',
          details: 'Calendar-year Partnerships – File a 2024 income tax return (Form 1065 or Form 1065-B) and provide each partner with a copy of Schedule K-1 (Form 1065) if an automatic six-month extension was filed.',
        },
        {
          date: 'September 15',
          details: 'Employers – Deposit Social Security, Medicare, and withheld income taxes for August if the monthly deposit rule applies.',
        },
        {
          date: 'September 15',
          details: 'Employers – Deposit nonpayroll withheld income tax for August if the monthly deposit rule applies.',
        },
        {
          date: 'September 30',
          details: 'Calendar-year Trusts and Estates – File a 2024 income tax return (Form 1041) if an automatic five-and-a-half-month extension was filed. Pay any tax, interest, and penalties due.',
        },
      ],
    },
    {
      month: 'October 2025',
      events: [
        { date: 'October 10', details: 'Individuals – Report September tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'October 15',
          details: 'Individuals – File a 2024 income tax return (Form 1040 or Form 1040-SR) if an automatic six-month extension was filed. Pay any tax, interest, and penalties due.',
        },
        {
          date: 'October 15',
          details: 'Retirement Plans – Make contributions for 2024 to certain existing retirement plans or establish and contribute to a SEP for 2024 if an automatic six-month extension was filed.',
        },
      ],
    },
    {
      month: 'November 2025',
      events: [
        { date: 'November 10', details: 'Individuals – Report October tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'November 17',
          details: 'Educational Institutions – File Form 941 for the third quarter of 2025. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until November 17 to file the return.',
        },
        {
          date: 'November 17',
          details: 'Estate and Trust Income Tax Returns – File Form 1041 and pay any tax, interest, and penalties due. Provide each beneficiary with a copy of Schedule K-1 (Form 1041) or a substitute Schedule K-1.',
        },
      ],
    },
    {
      month: 'December 2025',
      events: [
        { date: 'December 10', details: 'Individuals – Report November tip income of $20 or more to employers (Form 4070).' },
        {
          date: 'December 15',
          details: 'Calendar-year Corporations – Pay the fourth installment of 2025 estimated income taxes, completing Form 1120-W for the corporation’s records.',
        },
        {
          date: 'December 15',
          details: 'Employers – Deposit Social Security, Medicare, and withheld income taxes for November if the monthly deposit rule applies.',
        },
        {
          date: 'December 15',
          details: 'Employers – Deposit nonpayroll withheld income tax for November if the monthly deposit rule applies.',
        },
      ],
    }    
  ];

  return (
    <div>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Tax Due Dates</h1>
        <table>
          {taxDates.map((month, idx) => (
            <React.Fragment key={idx}>
              <tr className='bg-blue-500 text-white'>
                <td colSpan="2">{month.month}</td>
              </tr>
              {month.events.map((event, i) => (
                <tr key={i}>
                  <td>
                    <strong>{event.date}</strong>
                  </td>
                  <td>{event.details}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </table>
      </div>
    </div>
  );
};

export default TaxDueDates;
