import React from 'react'

const AssuranceServices = () => {
    return (
        <div className="min-h-screen bg-gray-100 py-12 px-6 lg:px-20">
            <div className="max-w-4xl mx-auto bg-white p-8 rounded-2xl shadow-lg">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    Assurance Services: Audits, Reviews, and Compilations
                </h1>
                <p className="text-lg text-gray-600 mb-6">
                    Building Trust Through Financial Transparency
                </p>
                <p className="text-gray-700 mb-6">
                    In today’s business environment, stakeholders require confidence in the
                    financial information presented by organizations. RM White CPA LLC offers a
                    range of assurance services designed to provide varying levels of scrutiny
                    and assurance, tailored to meet your specific needs.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Assurance Services Include:</h2>

                <div className="space-y-6">
                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Audits</h3>
                        <p className="text-gray-600 mt-2">
                            Providing the highest level of assurance, our audit services involve a
                            comprehensive examination of your financial statements and underlying
                            records. This process includes verifying account balances, assessing
                            internal controls, and ensuring compliance with applicable accounting
                            standards. An audit culminates in an independent auditor’s report
                            expressing an opinion on the fairness of your financial statements.
                        </p>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Reviews</h3>
                        <p className="text-gray-600 mt-2">
                            Offering a moderate level of assurance, a review engagement involves
                            analytical procedures and inquiries to provide limited assurance that no
                            material modifications are needed for your financial statements to
                            conform with accounting principles. This service is less extensive than
                            an audit but provides stakeholders with a degree of confidence in your
                            financial reporting.
                        </p>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Compilations</h3>
                        <p className="text-gray-600 mt-2">
                            In a compilation engagement, we assist management in presenting
                            financial information in the form of financial statements without
                            expressing any assurance on them. This service is ideal for internal use
                            or for entities where a higher level of assurance is not required.
                        </p>
                    </div>
                </div>

                <p className="text-gray-700 mt-6">
                    Choosing the appropriate level of assurance depends on various factors,
                    including regulatory requirements, stakeholder expectations, and the
                    specific needs of your organization. Our team at RM White CPA LLC will work
                    closely with you to determine the most suitable service, ensuring clarity,
                    compliance, and confidence in your financial reporting.
                </p>
            </div>
        </div>
    );
}

export default AssuranceServices

