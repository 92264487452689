import React from 'react'

const NonprofitOrganizationalFinancialServices = () => {
    return (
        <div className="min-h-screen bg-gray-100 py-12 px-6 lg:px-20">
            <div className="max-w-4xl mx-auto bg-white p-8 rounded-2xl shadow-lg">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    Nonprofit Organizational Financial Services
                </h1>
                <p className="text-lg text-gray-600 mb-6">
                    Empowering Nonprofits with Financial Excellence
                </p>
                <p className="text-gray-700 mb-6">
                    Nonprofit organizations face unique financial challenges,
                    from managing restricted funds to ensuring compliance with various regulations.
                    At RM White CPA LLC, we specialize in providing tailored financial solutions that address
                    the distinct needs of nonprofits, enabling them to focus on their mission-driven work.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Services for Nonprofit Organizations:</h2>

                <div className="space-y-6">
                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Financial Management and Planning:</h3>
                        <p className="text-gray-600 mt-2">
                            We assist in developing robust financial strategies, including budgeting, cash flow analysis,
                            and long-term financial planning, ensuring your organization’s sustainability.
                        </p>
                    </div>
                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Grant Management and Compliance:</h3>
                        <p className="text-gray-600 mt-2">
                            Our team ensures accurate tracking and reporting of grant funds, helping you maintain compliance
                            with donor requirements and avoid potential pitfalls.
                        </p>
                    </div>
                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Tax Preparation and Filing:</h3>
                        <p className="text-gray-600 mt-2">
                            Navigating the complexities of nonprofit tax regulations can be daunting.
                            We provide expert guidance on tax-exempt status, Unrelated Business Income Tax (UBIT),
                            and ensure timely and accurate filing of Form 990.
                        </p>
                    </div>
                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Internal Controls Assessment:</h3>
                        <p className="text-gray-600 mt-2">
                            We evaluate and enhance your internal controls to safeguard assets,
                            ensure financial data integrity, and promote operational efficiency.
                        </p>
                    </div>
                    <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                        <h3 className="text-xl font-semibold text-gray-700">Financial Statement Preparation:</h3>
                        <p className="text-gray-600 mt-2">
                            Our professionals prepare clear and comprehensive financial statements that reflect your organization’s financial health, 
                            fostering transparency and trust with stakeholders.
                        </p>
                    </div>
                </div>

                <p className="text-gray-700 mt-6">
                    Partner with RM White CPA LLC to navigate the financial complexities of the nonprofit sector.
                    Our expertise will empower your organization to achieve financial stability and focus on what
                    matters most—making a difference in the community.
                </p>
            </div>
        </div>
    );
}

export default NonprofitOrganizationalFinancialServices

