import React from 'react';

const BigServiceCard = ({ icon: Icon, title, description, iconColor }) => {
  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 rounded-lg shadow-md bg-white transition-all duration-300 ease-in-out hover:translate-y-[-5px] h-full">
      {/* Icon Container */}
      <div className="flex items-center justify-center w-16 h-16 rounded-full">
        <Icon className={`text-[64px] transition duration-500 ${iconColor}`} />
      </div>

      {/* Text Content */}
      <div className="flex flex-col flex-grow mt-4 sm:mt-0 sm:ml-6">
        <h4 className="font-bold text-lg">
          <a className="hover:text-[#F89E34] text-[#111]" href="/services/accounting-and-advisory-services">
            {title}
          </a>
        </h4>
        <p className="text-sm flex-grow">{description}</p>
      </div>
    </div>
  );
};

export default BigServiceCard;
